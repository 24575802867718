import Actions from "modules/actions";
import ConversionRule from "store/actions/conversionRule";
import Modifier from "store/actions/modifier";

const conversionRuleAction = new ConversionRule();
const modifierAction = new Modifier();

export default class ConversionRulesAction extends Actions {
  async createConversionRule(variables) {
    return conversionRuleAction.create(variables);
  }

  async getAllConversionRules(variables) {
    return conversionRuleAction.getAll(variables);
  }

  async getVbsConversionRule(variables) {
    return conversionRuleAction.get(variables);
  }
    
  async updateVBSConversionRule(variables){
    return conversionRuleAction.update(variables);
  }

  async updateVBSConversionRuleStatus(variables, status){
    return conversionRuleAction.updateStatus(variables, status);
  }

  async getAllModifier(variables) {
    return modifierAction.getAll(variables);
  }

  async createModifier(variables) {
    return modifierAction.create(variables);
  }
}
