import Actions from "modules/actions/conversion-rules-action/";
import { isEqual } from "lodash";

const actions = new Actions();

export default {
  name: "GeneralInformation",
  props: {
    conversionRule: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      requre: true,
    },
  },
  data() {
    return {
      canEdit: true,
      isSaving: false,
      dateMenu: false,
      dateMenu1: false,
      dateValue: "",
      dateValue1: "",
      isModalVisible: false,
      conversionRuleCopy: null,
      rules: {
        ruleID: (val) =>
          this.isMaxLength(val, 10) ||
          "This field must not exceeds more than 10 characters",
        ruleName: [
          (val) =>
            this.isMaxLength(val, 30) ||
            "This field must not exceeds more than 30 characters",

          (val) => !val || this.validateNameIfExist(val) || true,
        ],
        date: [(val) => !val || this.isValidDate(val) || "Invalid date."],
        ruleDesc: [
          (val) =>
            this.isMaxLength(val, 60) ||
            "This field must not exceeds more than 60 characters",
          (val) =>
            /^[a-zA-Z0-9\s .-]*$/.test(val) ||
            "This field only accepts alphanumeric, dash and period.",
        ],
        hasSpecialChars: (val) =>
          /^[a-zA-Z0-9 ]*$/.test(val) ||
          "This field should not have special characters.",
        required: (val) => {
          if (
            val === null ||
            val === undefined ||
            val.length === 0 ||
            val === ""
          ) {
            return "This field is required";
          }
          return true;
        },

        validTerminationDate: (val, effectivityDate) => {
          if (!val) return true;
          const terminationDate = new Date(val);
          const effectDate = new Date(effectivityDate);
          return (
            terminationDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  computed: {
    isEditMode() {
      return this.type == "ADD" ? false : true;
    },

    __ConversionRule: {
      get() {
        return this.conversionRule;
      },
      set(value) {
        this.$emit("update:conversionRule", value);
      },
    },
  },
  methods: {
    compareFieldsInGenInfo() {
      let hasChanges = false;
      const obj = Object.keys(this.conversionRuleCopy);

      obj.forEach((index) => {
        if (
          index == "description" ||
          index == "validityStartDate" ||
          index == "validityEndDate"
        ) {
          if (
            !isEqual(
              this.__ConversionRule[index],
              this.conversionRuleCopy[index]
            )
          ) {
            hasChanges = true;
            return;
          }
        }
      });

      return hasChanges;
    },

    async validateNameIfExist(ruleName) {
      if(this.isEditMode) return true;
      
      try {
        let result = await actions.getAllConversionRules({
          limit: 1,
          offset: null,
          filter: {
            name: ruleName,
            enum_patternMatch: "EXACT",
            archive: true,
            logicalOperator: "AND",
          },
          limitData: ["name"],
        });
        if (result.length) {
          return "Name of Rule already exists.";
        } else {
          return true;
        }
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while validating Name of Rule.",
          type: "danger",
        });

        return "Error while validating Name of Rule";
      }
    },
    isMaxLength(val, max) {
      if (!val) return true;
      else if (val.length === 0) return false;
      else if (max < val.length) return false;
      return true;
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";

      const [month, day, year] = date.split("/");
      return month && day && year
        ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
        : null;
    },
    formatDate(date) {
      if (!date) return "";
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal() {
      this.__ConversionRule.validityStartDate = this.formatDate(this.dateValue);
      this.dateMenu = false;
    },
    closeDateModal1() {
      this.__ConversionRule.validityEndDate = this.formatDate(this.dateValue1);
      this.dateMenu1 = false;
    },
    
    cancelEdit() {
      if (this.compareFieldsInGenInfo()) {
        this.isModalVisible = true;
      } else {
        this.canEdit = false;
      }
    },

    confirmDiscardChanges() {
      this.isModalVisible = false;
      this.canEdit = false;

      this.__ConversionRule = JSON.parse(
        JSON.stringify(this.conversionRuleCopy)
      );
    },
  },

  created() {
    this.$nextTick(() => {
      this.conversionRuleCopy = JSON.parse(
        JSON.stringify(this.__ConversionRule)
      );
    });
    if (this.isEditMode) {
      this.canEdit = false;
    }
  },
};
