export default {
  name: "Exclusion",
  props: {
    data: { type: Array },
    type: {
      type: String,
      requre: true,
    },
  },

  data() {
    return {
      canEdit: false,
      dataTable: {
        tab: 0,
        tabs: ["Payor Sub Group", "Payor", "Client"],
        loading: false,
        headers: [
          [
            {
              text: "Payor Sub Group",
              align: "left",
              field: "label",
              sortable: false,
            },
          ],
          [
            {
              text: "Payor Name",
              align: "left",
              field: "name",
              sortable: false,
            },
            {
              text: "Payor Acronym",
              align: "left",
              field: "insuranceCompanyId",
              sortable: false,
            },
            {
              text: "Payor Sub Group",
              align: "left",
              field: "payorSubGroup",
              sortable: false,
            },
            {
              text: "Status",
              align: "left",
              field: "status",
              sortable: false,
              isStatusColumn: true,
            },
          ],
          [
            {
              text: "Client Name",
              align: "left",
              field: "payorName",
              sortable: false,
            },
            {
              text: "Client ID",
              align: "left",
              field: "payorCode",
              sortable: false,
            },
            {
              text: "Status",
              align: "left",
              field: "status",
              sortable: false,
              isStatusColumn: true,
            },
          ],
        ],
      },
    };
  },
  computed: {
    activeTab() {
      return this.dataTable.tabs[this.dataTable.tab]
        .replace(" ", "")
        .replace(/\b(\w)/g, (s) => s.toLowerCase());
    },
    noDataMsg() {
      return `There is no ${this.dataTable.tabs[
        this.dataTable.tab
      ].toLowerCase()} associated with this Conversion Rule`;
    },

    btnText() {
      const actionLbl = "Add";
      return `${actionLbl} Exclusion(s)`;
    },
  },
  methods: {
    viewClient() {
      this.$emit("openModal");
    },
  },
};
