import StoreActions from "store/actions";
import ConversionRule from "store/models/ConversionRule";

const storeConversionRuleActions = new StoreActions(ConversionRule);

export default class ConversionRulesAction {
  async create(params) {
    const { queries, variables, collectionQueries, limitData } = params;
    return await storeConversionRuleActions.mutation(
      {
        queryName: "createVbsConversionRule",
        relativeUrl: "/conversion-rules/create-rule",
        queries: queries || null,
        collectionQueries: collectionQueries || [],
        shouldNotReturnId: true,
        limitData: limitData || null,
      },
      "To create new rule.",
      { ...variables }
    );
  }

  async getAll(variables, desciption = "") {
    return await storeConversionRuleActions.query(
      {
        queryName: "vbsConversionRules",
        relativeUrl: "/conversion-rules/get-all",
        ...variables,
      },
      desciption.length ? desciption : "to load Conversion list."
    );
  }

  async get(params) {
    const { limitData, variables } = params;

    return await storeConversionRuleActions.query(
      {
        queryName: "getVbsConversionRule",
        relativeUrl: "/conversion-rules/get",
        limitData: limitData || null,
      },
      "To fetch conversion rule.",
      { ...variables }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;

    return await storeConversionRuleActions.mutation(
      {
        relativeUrl: "/conversion-rules/update-rule",
        queryName: "updateVbsConversionRule",
        queries: queries || null,
        collectionQueries: collectionQueries || null,
      },
      "To update conversion rule.",
      {
        ...variables,
      }
    );
  }

  async updateStatus(variables, isActive) {
    const {
      conversionID,
      limitData = [],
      shouldNotReturnId = false,
    } = variables;
    return await storeConversionRuleActions.mutation(
      {
        relativeUrl: "/conversion-rules/update-rule",
        queryName: isActive
          ? "deactivateVbsConversionRule"
          : "activateVbsConversionRule",
        limitData,
        shouldNotReturnId,
      },
      "To update status of conversion rule.",
      {
        id: conversionID,
      }
    );
  }
}
