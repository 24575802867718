import Test from "store/models/Test";
import Modifier from "store/models/Modifier";
import CPTCode from "store/models/CPTCode";
import { isEqual } from "lodash";

import Actions from "modules/actions/fee-schedule-action";

const actions = new Actions();

export default {
  name: "ConversionTable",
  props: {
    ruleItems: {
      type: Array,
      require: true,
    },
    type: {
      type: String,
      requre: true,
    },
  },
  data() {
    return {
      canEdit: false,
      isModalVisible: false,
      testModel: Test,
      modifierModel: Modifier,
      cptModel: CPTCode,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          width: "5px",
        },
        {
          text: "Test ID",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "CPT",
          align: "left",
          sortable: false,
          width: "180px",
        },
        {
          text: "Modifier",
          align: "left",
          sortable: false,
          width: "150px",
        },
        {
          text: "Unit",
          align: "left",
          sortable: false,
          width: "100px",
        },
        //Convert As header
        {
          text: "Test ID",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "CPT",
          align: "left",
          sortable: false,
          width: "180px",
        },
        {
          text: "Modifier",
          align: "left",
          sortable: false,
          width: "150px",
        },
        {
          text: "Unit",
          align: "left",
          sortable: false,
          width: "100px",
        },
        {
          text: "",
          align: "left",
          sortable: false,
          width: "80px",
        },
      ],
      rules: {
        allowableModifier: (val) => {
          if (!val) return true;

          return val.value.length >= 3
            ? "Max of 2 characters alphanumeric"
            : true;
        },
        allowableUnit: [
          (val) => {
            return (
              /^[0-9]*$/.test(val) ||
              "this field only allows numeric characters."
            );
          },
          (val) => {
            return val >= 1 || "this field only greater than 0 value";
          },
        ],
        required: (val) => {
          if (
            val === null ||
            val === undefined ||
            val.length === 0 ||
            val === ""
          ) {
            return "This field is required";
          }
          return true;
        },
      },
      actions: actions,

      isLoading: false,
      render: true,
      rowNumber: -1,

      ruleItemsCopy: [],
    };
  },
  computed: {
    isEditMode() {
      return this.type == "ADD" ? false : true;
    },

    __RuleItems: {
      get() {
        return this.ruleItems;
      },
      set(value) {
        this.$emit("update:ruleItems", value);
      },
    },
    isDisabled() {
      if ((this.isEditMode && this.canEdit) || !this.isEditMode) {
        return false;
      }
      return true;
    },
  },
  methods: {
    validateCombinations() {
      const conversionRow = [...this.__RuleItems];
      const foundDuplicateRow = conversionRow.find((outer, index) => {
        return conversionRow.find((inner, ind) => {
          const innerInbound = inner.inbound;
          const outerInbound = outer.inbound;

          return (
            index !== ind && this.validateObject(innerInbound, outerInbound)
          );
        });
      });

      if (foundDuplicateRow) {
        this.rowNumber = conversionRow.findIndex((inner) => {
          const innerInbound = inner.inbound;
          const outerInbound = foundDuplicateRow.inbound;

          return (
            !innerInbound.isCreated &&
            this.validateObject(innerInbound, outerInbound)
          );
        });
      } else {
        this.rowNumber = conversionRow.findIndex((outer) => {
          return this.validateConvertAs(outer.outbound);
        });
      }

      return this.rowNumber >= 0 ? false : true;
    },

    validateConvertAs(outbound) {
      const foundDuplicateAtOutBound = outbound.find((outer, index) => {
        return outbound.find((inner, ind) => {
          return index !== ind && this.validateObject(inner, outer);
        });
      });
      return foundDuplicateAtOutBound ? true : false;
    },

    validateObject(innerObj, outerObj) {
      return (
        innerObj.test.value === outerObj.test.value &&
        (innerObj.cptCode && outerObj.cptCode
          ? innerObj.cptCode.value === outerObj?.cptCode.value
          : !innerObj.cptCode && !outerObj.cptCode
          ? innerObj.cptCode?.value === outerObj.cptCode?.value
          : false) &&
        (innerObj.modifier && outerObj.modifier
          ? innerObj.modifier.value === outerObj?.modifier.value
          : !innerObj.modifier && !outerObj.modifier
          ? innerObj.modifier?.value === outerObj.modifier?.value
          : false)
      );
    },

    validateCreateModifier(searchVal) {
      return (
        /^[a-zA-Z0-9 ]*$/.test(searchVal) ||
        "Modifier only allows alphanumeric characters."
      );
    },

    populateCode(e, index, isInbound = true, outIndx = 0) {
      if (this.objectHasOwnProperty(e, "id")) {
        if (index === this.rowNumber) {
          this.rowNumber = -1;
        }
        const { id, label, code, modifierCode, __typename } = e;
        const updatedObj = {
          value: id,
          label: label ? label : code ? code : modifierCode,
        };

        let field = null;

        if (__typename == "Test") {
          this.__RuleItems[index]["inbound"].test = updatedObj;
          this.__RuleItems[index]["outbound"].forEach(
            (item) => (item.test = updatedObj)
          );
          this.render = false;
          this.$nextTick(() => {
            this.render = true;
          });
          return;
        } else if (__typename == "Modifier") {
          field = "modifier";
        } else {
          field = "cptCode";
        }

        const boundObject = isInbound ? "inbound" : "outbound";

        if (isInbound) {
          this.__RuleItems[index][boundObject][field] = updatedObj;
        } else {
          this.__RuleItems[index][boundObject][outIndx][field] = updatedObj;
        }
      }
    },
    atClear(index, type, isInbound = true, outIdx) {
      const boundObject = isInbound ? "inbound" : "outbound";

      if (type == "Test") {
        this.__RuleItems[index]["inbound"].test = null;
        this.__RuleItems[index]["outbound"].forEach(
          (data) => (data.test = null)
        );
        this.render = false;
        this.$nextTick(() => {
          this.render = true;
        });
      } else if (type == "Modifier") {
        if (isInbound) this.__RuleItems[index][boundObject].modifier = null;
        else this.__RuleItems[index][boundObject][outIdx].modifier = null;
      } else {
        if (isInbound) this.__RuleItems[index][boundObject].cptCode = null;
        this.__RuleItems[index][boundObject][outIdx].cptCode = null;
      }
    },
    addMore() {
      this.__RuleItems.push({
        inbound: {
          test: null,
          cptCode: null,
          modifier: null,
          unit: "1",
        },
        outbound: [
          {
            test: null,
            cptCode: null,
            modifier: null,
            unit: "1",
          },
        ],
        isCreated: false,
      });
    },
    onDelete(index) {
      this.render = false;
      this.$nextTick(() => {
        this.__RuleItems.splice(index, 1);
        this.render = true;
      });
    },
    cancelEdit() {
      if (this.compareRuleItems()) {
        this.isModalVisible = true;
      } else {
        this.canEdit = false;
      }
    },

    compareRuleItems() {
      const hasChanges = !isEqual(
        JSON.stringify(
          this.ruleItemsCopy.map((item) => ({
            inbound: item.inbound,
            outbound: item.outbound,
          }))
        ),
        JSON.stringify(
          this.__RuleItems.map((item) => ({
            inbound: item.inbound,
            outbound: item.outbound,
          }))
        )
      );
      return hasChanges;
    },

    addConvertAs(index) {
      const testObj = this.__RuleItems[index].inbound?.test;

      this.__RuleItems[index].outbound.push({
        test: testObj ? testObj : null,
        cptCode: null,
        modifier: null,
        unit: "1",
      });
    },

    onDeleteConvertAs(index, outIndx) {
      this.render = false;
      this.$nextTick(() => {
        this.__RuleItems[index].outbound.splice(outIndx, 1);
        this.render = true;
      });
    },

    confirmDiscardChanges() {
      this.isModalVisible = false;
      this.canEdit = false;
      this.__RuleItems = JSON.parse(JSON.stringify(this.ruleItemsCopy));
    },
  },

  created() {
    this.ruleItemsCopy = JSON.parse(JSON.stringify(this.__RuleItems));
    if (this.isEditMode) {
      this.headers.splice(this.headers.length - 1, 0, {
        text: "Creation Date",
        align: "left",
        sortable: false,
        width: "40px",
      });
    }
  },
};
