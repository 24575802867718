import GeneralInformation from "./sections/GeneralInformation";
import Exclusion from "./sections/Exclusion";
import ConversionTable from "./sections/ConversionTable";
import SearchPaginationModal from "../../../../../components/SearchDataTableModal";
import Actions from "modules/actions/conversion-rules-action/";
import PayorAccounts from "../../../../../store/models/PayorAccount";
import InsuranceCompany from "../../../../../store/models/InsuranceCompany";
import { isEqual } from "lodash";

const actions = new Actions();

const DIALOG_EXCLUSION = "EXCLUSION";
const DIALOG_PAGE = "PAGE";
const TYPE_ADD = "ADD";
const TYPE_EDIT = "EDIT";

export default {
  name: "AddConversionRules",
  components: {
    GeneralInformation,
    Exclusion,
    ConversionTable,
    SearchPaginationModal,
  },
  data() {
    return {
      isSaving: false,
      isLoading: false,
      dialog: false,
      openSearchModal: false,
      openViewPayorModal: false,
      confirmationModal: false,
      type: TYPE_ADD,
      loaderType:
        "article, list-item-two-line, list-item-two-line, card-heading, list-item-three-line, image, actions",
      isAtPageBottom: false,
      canEdit: false,

      payorSubGroupConfig: {
        isCustomTable: true,
      },

      payorSearchModalConfig: {
        searchLabel: "Type in Payor Name",
        modelInstance: InsuranceCompany,
        queryName: "insuranceCompanies",
        relativeUrl: "/insurance-company/all-list",
        searchBy: "name",
        defaultSort: {
          by: "orderByCompanyId",
          order: "ASC",
        },
        limitData: [
          "name",
          "insuranceCompanyId",
          "payorSubGroup",
          "inactive",
          "totalCount",
        ],
        searchLogicalOperand: "AND",

        mapDataCollection: this.mapPayorsTab,

        filterField: {
          label: "Select Payor Sub Group",
          field: "enum_payorSubGroup",
          selectOptions: [],

          rule: [
            (val) => {
              if (!val) {
                return true;
              }
              if (this.compareDropdown(val)) {
                return "Payors already selected from the Payor subgroup tab";
              }
              return true;
            },
          ],
        },
      },

      clientSearchModalConfig: {
        searchLabel: "Type in Client Name",
        modelInstance: PayorAccounts,
        queryName: "payorAccounts",
        relativeUrl: "/payor-accounts/get-payor-accounts",
        searchBy: "payorName",
        limitData: ["payorCode", "payorName", "archived", "totalCount"],

        searchLogicalOperand: "AND",

        mapDataCollection: this.mapClientsTab,
      },

      headersWithTab: [
        [
          {
            text: "Payor Sub Group",
            align: "left",
            value: "label",
            sortable: false,
          },
          {
            text: "",
            align: "left",
            value: "Action",
            sortable: false,
          },
        ],
        [
          {
            text: "Payor Name",
            align: "left",
            field: "name",
            sortable: false,
          },
          {
            text: "Payor Acronym",
            align: "left",
            field: "insuranceCompanyId",
            sortable: false,
          },
          {
            text: "Payor Sub Group",
            align: "left",
            field: "payorSubGroup",
            sortable: false,
          },
          {
            text: "Status",
            align: "left",
            field: "status",
            sortable: false,
            isStatusColumn: true,
          },
        ],
        [
          {
            text: "Client Name",
            align: "left",
            field: "payorName",
            sortable: false,
          },
          {
            text: "Client ID",
            align: "left",
            field: "payorCode",
            sortable: false,
          },
          {
            text: "Status",
            align: "left",
            field: "status",
            sortable: false,
            isStatusColumn: true,
          },
        ],
      ],
      conversionRule: {
        ruleID: "",
        ruleName: "",
        description: "",
        validityStartDate: "",
        validityEndDate: "",
        creationDate: this.formatDate(new Date()),
        ruleItems: [
          {
            inbound: {
              test: null,
              cptCode: null,
              modifier: null,
              unit: "1",
            },
            outbound: [
              {
                test: null,
                cptCode: null,
                modifier: null,
                unit: "1",
              },
            ],
          },
        ],
      },
      payorSubGroups: [],
      tempSelectedSubGroup: [],
      selectedSubGroup: [],
      selectedPayorClients: {
        payor: [],
        client: [],
      },
      copy: {
        conversionRule: {
          ruleID: "",
          ruleName: "",
          description: "",
          validityStartDate: "",
          validityEndDate: "",
          creationDate: this.formatDate(new Date()),
          ruleItems: [
            {
              inbound: {
                test: null,
                cptCode: null,
                modifier: null,
                unit: "1",
              },
              outbound: [
                {
                  test: null,
                  cptCode: null,
                  modifier: null,
                  unit: "1",
                },
              ],
            },
          ],
        },
        selectedSubGroup: [],
        selectedPayorClients: {
          payor: [],
          client: [],
        },
      },
      numberOfSelected: 0,
      viewSubgroup: "",
      discardType: DIALOG_PAGE,
      canEditExclusion: false,

      singlePayorDataConfig: {
        sectionNote: "Select payor(s) to associate the fee schedule with:",
        searchLabel: "Search Payor Name",
        modelInstance: InsuranceCompany,
        queryName: "insuranceCompanies",
        relativeUrl: "/insurance-company/all-list",
        searchBy: "name",
        limitData: [
          "name",
          "insuranceCompanyId",
          "payorSubGroup",
          "inactive",
          "totalCount",
        ],
        initialFilter: null,
        mapDataCollection: this.mapPayorsTab,
      },
      payorHeaders: [
        {
          text: "Payor Name",
          align: "left",
          field: "name",
          sortable: false,
        },
        {
          text: "Payor Acronym",
          align: "left",
          field: "insuranceCompanyId",
          sortable: false,
        },
        {
          text: "Payor Sub Group",
          align: "left",
          field: "payorSubGroup",
          sortable: false,
        },
        {
          text: "Status",
          align: "left",
          field: "status",
          sortable: false,
          isStatusColumn: true,
        },
      ],
    };
  },

  watch: {
    tempSelectedSubGroup() {
      this.numberOfSelected =
        this.tempSelectedSubGroup.length === 0
          ? ""
          : `${this.tempSelectedSubGroup.length} record${
              this.tempSelectedSubGroup.length > 1 ? "s" : ""
            } selected`;

      if (this.openSearchModal) {
        this.$eventBus.$emit(
          "insuranceCompaniesRemoveSelectedIds",
          this.filterSelectedSubGroup
        );

        this.$eventBus.$emit("insuranceCompaniesRemoveSelectedFilter");
      }
    },
  },
  computed: {
    tabsSearchConfig() {
      return [
        this.payorSubGroupConfig,
        this.payorSearchModalConfig,
        this.clientSearchModalConfig,
      ];
    },
    hasChanges() {
      return ![
        isEqual(this.conversionRule, this.copy.conversionRule),
        isEqual(this.selectedSubGroup, this.copy.selectedSubGroup),
        isEqual(this.selectedPayorClients, this.copy.selectedPayorClients),
      ].every((item) => item);
    },
    selectedExclusionData() {
      return [
        [...this.selectedSubGroup],
        [...this.selectedPayorClients?.payor] || [],
        [...this.selectedPayorClients?.client] || [],
      ];
    },

    hasSelectedExclusionData() {
      return this.selectedExclusionData.some((item) => item.length >= 1);
    },

    showSearchModal() {
      return (
        (this.type === TYPE_ADD && this.payorSubGroups) ||
        (this.type === TYPE_EDIT && this.hasSelectedExclusionData)
      );
    },
  },
  methods: {
    setSelectDeselectAll() {
      if (!this.tempSelectedSubGroup.length) {
        return JSON.parse(JSON.stringify(this.payorSubGroups));
      } else {
        return [];
      }
    },
    filterSelectedSubGroup(item) {
      return this.tempSelectedSubGroup.every(
        (selected) => selected.value != item.payorSubGroupEnum
      );
    },
    compareDropdown(value) {
      return this.tempSelectedSubGroup.some(
        (selected) => selected.value == value
      );
    },
    mapPayorsTab(rowItems) {
      const data = rowItems.map(
        ({
          id,
          name,
          insuranceCompanyId,
          payorSubGroup,
          inactive,
          totalCount,
        }) => ({
          id,
          name,
          insuranceCompanyId,
          payorSubGroup: this.formatTitle(payorSubGroup),
          payorSubGroupEnum: payorSubGroup,
          status: !inactive ? "Active" : "Deactivated",
          totalCount,
        })
      );

      return data;
    },
    mapClientsTab(rowItems) {
      const data = rowItems.map(
        ({ id, payorCode, payorName, archived, totalCount }) => ({
          id,
          payorCode,
          payorName,
          status: !archived ? "Active" : "Deactivated",
          totalCount,
        })
      );

      return data;
    },

    viewPayorsInSubGroup(index) {
      const { value, label } = this.payorSubGroups[index];
      this.viewSubgroup = label;
      this.singlePayorDataConfig.initialFilter = {
        enum_payorSubGroup: value,
      };
      this.$nextTick(() => {
        this.openViewPayorModal = true;
      });
    },

    async getAllPayorSubGroup() {
      try {
        if (!this.payorSubGroups.length) {
          const result = await actions.getEnumValues("PayorSubGroup");

          if (result) {
            const list = result.enumValues.map((item) => {
              return {
                label: this.formatTitle(item.name),
                value: item.name,
              };
            });
            this.payorSearchModalConfig.filterField.selectOptions = [...list];
            this.payorSubGroups = [...list];
          } else {
            this.showNotifyMessage({
              type: "danger",
              message: "Unable to fetch data.",
            });
          }
        }
      } catch (err) {
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    formatTitle(value) {
      if (value === "UNK") return value;
      if (value.includes("HMO") || value.includes("PPO")) {
        const index =
          value.indexOf("HMO") >= 0
            ? value.indexOf("HMO")
            : value.indexOf("PPO");
        return (
          value.substring(0, index) + " " + value.substring(index, value.length)
        );
      }
      return value.replace(/([A-Z])/g, " $1");
    },

    formatDate(value, format = "MM/DD/YYYY") {
      if (!value) return null;
      return this.$options.filters.changeDateFormat(value, format);
    },

    processSubGroupExclusion(action = "INCLUDE") {
      let collectionEnum = [];
      this.selectedSubGroup.forEach((subgroup) => {
        collectionEnum.push(subgroup.value);
      });

      const subGroupParam = {
        payorSubGroups: collectionEnum,
        exclusionAction: action,
      };

      return collectionEnum.length > 0 ? subGroupParam : null;
    },

    processExclusion(exclusions, action = "INCLUDE") {
      let ids = [];
      exclusions.forEach((exclusion) => {
        ids.push(exclusion.id);
      });

      const excludeParam = {
        ids: ids,
        exclusionAction: action,
      };

      return ids.length > 0 ? excludeParam : null;
    },

    processConversion() {
      const ruleItems = this.conversionRule.ruleItems.map((ruleItem) => {
        const { id, inbound, outbound } = ruleItem;

        let newObj = {
          inbound: this.mapBound(inbound),
          convertAs: outbound.map((boundObj) => {
            return this.mapBound(boundObj);
          }),
        };
        if (id) newObj["id"] = id;
        return newObj;
      });

      return ruleItems;
    },

    mapBound(boundObj) {
      const bound = {
        testId: boundObj.test.value,
        unit: parseInt(boundObj.unit),
      };

      if (boundObj.cptCode?.value) bound["cptCodeId"] = boundObj.cptCode.value;

      if (boundObj.modifier?.value)
        bound["modifierId"] = boundObj.modifier.value;

      if (boundObj?.id) {
        bound["id"] = boundObj.id;
      }

      return bound;
    },

    async setEditMode() {
      await this.getConversionRuleByID();
      this.setSectionEditMode(true);
    },

    setSectionEditMode(status) {
      this.canEdit = status;
      const { generalInfo, conversionTable, exclusion } = this.$refs;
      generalInfo.canEdit = status;
      conversionTable.canEdit = status;
      exclusion.canEdit = status;
    },

    showModal() {
      this.openSearchModal = true;
      this.$nextTick(() => {
        this.$refs.searchPagination.tab = "0";

        this.canEditExclusion = true;
        this.$eventBus.$emit("searchModalChangeCanEdit", true);

        this.$eventBus.$emit("payorAccountsRefreshList");

        
        if(this.type === TYPE_EDIT)
          this.updateModalSelectedExclusion();
      });
    },

    onCancelPage() {
      this.discardType = DIALOG_PAGE;
      if (this.hasChanges) {
        this.dialog = true;
      } else if (this.type !== TYPE_EDIT && !this.hasChanges) {
        this.$router.push("/system-maintenance/conversion-rules");
      } else if (this.type == TYPE_EDIT && !this.hasChanges) {
        this.setSectionEditMode(false);
      }
    },

    onCancelSearchModal(selected) {
      this.discardType = DIALOG_EXCLUSION;

      if (!this.compareExclusion(selected, this.selectedPayorClients)) {
        this.dialog = true;
      } else {
        this.openSearchModal = false;
      }
    },

    onSelectExclusion(exclusions) {
      this.selectedPayorClients = JSON.parse(JSON.stringify(exclusions));
      this.selectedSubGroup = [...this.tempSelectedSubGroup];
      this.openSearchModal = false;
    },

    async onSaveExclusion(selected) {
      if (this.compareExclusion(selected, this.selectedPayorClients)) {
        this.showNotifyMessage({
          message: `No changes were made.`,
          type: "danger",
        });
        return;
      }
      this.onSelectExclusion(selected);
    },

    onEditExclusion(canEdit) {
      this.canEditExclusion = canEdit;
    },

    onDiscard() {
      if (this.discardType === DIALOG_PAGE && this.type !== TYPE_EDIT) {
        this.$router.push("/system-maintenance/conversion-rules");
      } else if (this.discardType === DIALOG_PAGE && this.type === TYPE_EDIT) {
        this.conversionRule = JSON.parse(
          JSON.stringify(this.copy.conversionRule)
        );

        this.selectedSubGroup = [...this.copy.selectedSubGroup];
        this.selectedPayorClients = JSON.parse(
          JSON.stringify(this.copy.selectedPayorClients)
        );

        this.tempSelectedSubGroup = [...this.selectedSubGroup];
        this.setSectionEditMode(false);
      } else if (this.discardType === DIALOG_EXCLUSION) {
        this.dialog = false;

        this.$refs.searchPagination.disabledSave = false;

        this.updateModalSelectedExclusion();

        this.tempSelectedSubGroup = [...this.selectedSubGroup];

        this.$nextTick(() => {
          this.openSearchModal = false;
        });
      }
    },

    updateModalSelectedExclusion() {
      const objectNames = Object.keys(this.selectedPayorClients).filter(
        (itemName) => itemName !== "payorSubGroup"
      );
      const { formDataTable } = this.$refs.searchPagination.$refs;
      this.$refs.searchPagination.selectedInTabs = JSON.parse(
        JSON.stringify({ payorSubGroup: this.selectedSubGroup, ...this.selectedPayorClients})
      );

      formDataTable.forEach((dataTable, index) => {
        const curDataTable = dataTable;

        curDataTable.selectedInTable =
          [...this.selectedPayorClients[objectNames[index]]];
        curDataTable.$refs.searchDataTable.selected = [
          ...this.selectedPayorClients[objectNames[index]],
        ];
        curDataTable.updateNumberOfSelected();
      });
    },

    onKeepEditing() {
      this.dialog = false;
      if (this.discardType != DIALOG_PAGE) {
        this.$refs.searchPagination.canEdit = true;
        this.$refs.searchPagination.disabledSave = this.$refs.searchPagination.hasSelectedExclusion()
      }
    },

    compareExclusion(newSelected, currentSelected) {
      const hasChanges = [
        isEqual(
          this.tempSelectedSubGroup.map((item) => item.value),
          this.selectedSubGroup.map((item) => item.value)
        ),
        isEqual(
          newSelected.payor.map((item) => item.id),
          currentSelected.payor.map((item) => item.id)
        ),
        isEqual(
          newSelected.client.map((item) => item.id),
          currentSelected.client.map((item) => item.id)
        ),
      ];
      return hasChanges.every((item) => item);
    },

    valueHasChanged(field, isDate) {
      const { conversionRule } = this.copy;
      const defaultValue = conversionRule[field];
      if (defaultValue && isDate) {
        return !(defaultValue.split(' ')[0] === this.conversionRule[field]);
      }
      return !(defaultValue === this.conversionRule[field]);
    },
    async onSave() {
      try {
        const generalFormValidate =
          await this.$refs.generalInfo.$refs.form.validateAsync();

        const rulesFormValidate =
          await this.$refs.conversionTable.$refs.form.validateAsync();

        if (!generalFormValidate || !rulesFormValidate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }

        if (!this.$refs.conversionTable.validateCombinations()) {
          this.showNotifyMessage({
            message: `System does not accept similar test id, cpt, and modifier. Please provide a unique value from any of these identifier:  test id, or cpt, or modifier.`,
            type: "danger",
          });
          return;
        }
        const { payor, client } = this.selectedPayorClients;

        if (!this.hasSelectedExclusionData) {
          this.showNotifyMessage({
            message: `Please select atleast 1 in either payor sub group, payor, and client`,
            type: "danger",
          });
          return;
        }

        const paramExcludeSubGroups = this.processSubGroupExclusion();
        const paramExcludePayors = this.processExclusion(payor);
        const paramExcludeClients = this.processExclusion(client);

        const ruleItems = this.processConversion();

        const { ruleName, description, validityStartDate, validityEndDate } =
          this.conversionRule;

        let paramRuleInfo = {
          name: ruleName,
          description: description,
          validityStartDate: this.$options.filters.getTimeDate(
            validityStartDate,
            "YYYY-MM-DD HH:mm:ss",
            false,
            null,
            this.getTimezoneOffset({
              date: validityStartDate,
            })
          ).utc,
          validityEndDate: validityEndDate
            ? this.$options.filters.getTimeDate(
                validityEndDate,
                "YYYY-MM-DD HH:mm:ss",
                false,
                null,
                {
                  days: 1,
                  ...this.getTimezoneOffset({
                    date: validityEndDate,
                    minusSeconds: 1,
                  }),
                }
              ).utc
            : null,

          ruleItems: ruleItems,
        };

        if (paramExcludeSubGroups) {
          paramRuleInfo["exclusionPayorSubGroups"] = paramExcludeSubGroups;
        }

        if (paramExcludePayors) {
          paramRuleInfo["exclusionPayors"] = paramExcludePayors;
        }
        if (paramExcludeClients) {
          paramRuleInfo["exclusionClients"] = paramExcludeClients;
        }

        this.isSaving = true;
        const params = {
          variables: {
            input: {
              type: "VbsConversionRuleInput!",
              value: paramRuleInfo,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: [],
          limitData: ["success", "errors"],
        };
        const { success, errors } = await actions.createConversionRule(params);
        if (success) {
          this.showNotifyMessage({
            message: "Successfully saved conversion rule data.",
            type: "success",
          });
          this.$router.push("/system-maintenance/conversion-rules");
        } else if (errors) {
          const errorMesssage =
            errors.length > 0
              ? errors[0]
              : "Problem has occurred while saving data.";

          this.showNotifyMessage({
            message: errorMesssage,
            type: "danger",
          });
        }

        this.isSaving = false;
      } catch (e) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
    },

    async onSubmitUpdate() {
      if (!this.hasChanges) {
        this.showNotifyMessage({
          message: `No changes were made.`,
          type: "danger",
        });
        return;
      }
      this.confirmationModal = true;
    },

    async onConfirmUpdate() {
      this.confirmationModal = false;
      await this.onUpdate();
    },

    async onUpdate() {
      try {
        const generalFormValidate =
          await this.$refs.generalInfo.$refs.form.validateAsync();

        const rulesFormValidate =
          await this.$refs.conversionTable.$refs.form.validateAsync();

        if (!generalFormValidate || !rulesFormValidate) {
          this.showNotifyMessage({
            message: `Please provide all necessary fields.`,
            type: "danger",
          });
          return;
        }

        if (!this.$refs.conversionTable.validateCombinations()) {
          this.showNotifyMessage({
            message: `System does not accept similar test id, cpt, and modifier. Please provide a unique value from any of these identifier:  test id, or cpt, or modifier.`,
            type: "danger",
          });
          return;
        }

        if (!this.hasSelectedExclusionData) {
          this.showNotifyMessage({
            message: `Please select atleast 1 in either payor sub group, payor, and client`,
            type: "danger",
          });
          return;
        }

        this.isSaving = true;

        const { payor, client } = this.selectedPayorClients;

        const paramExcludeSubGroups = this.processSubGroupExclusion();
        const paramExcludePayors = this.processExclusion(payor);
        const paramExcludeClients = this.processExclusion(client);

        const paramRuleItems = this.processConversion();

        let paramExclusions = {};

        if (paramExcludeSubGroups) {
          paramExclusions["exclusionPayorSubGroups"] = paramExcludeSubGroups;
        }

        if (paramExcludePayors) {
          paramExclusions["exclusionPayors"] = paramExcludePayors;
        }
        if (paramExcludeClients) {
          paramExclusions["exclusionClients"] = paramExcludeClients;
        }

        const { description, validityStartDate, validityEndDate } = this.conversionRule;
        const collectionQueries = [];
        const hasDateChanges = this.valueHasChanged('validityStartDate', true) || this.valueHasChanged('validityEndDate', true);
        const hasRulesAndExclusionsChanges = ![
          isEqual(this.conversionRule.ruleItems, this.copy.conversionRule.ruleItems),
          isEqual(this.selectedSubGroup, this.copy.selectedSubGroup),
          isEqual(this.selectedPayorClients, this.copy.selectedPayorClients),
        ].every(item => item);

        if (this.valueHasChanged('description')) {
          collectionQueries.push({
            updateDescription: {
              description: {
                type: "String",
                value: description,
              },
              mutationReturn: ["success"],
            }
          });
        }
        if (hasDateChanges) {
          collectionQueries.push({
            updateValidityDates: {
              validityStartDate: {
                type: "String",
                value: validityStartDate
                  ? this.$options.filters.getTimeDate(
                      validityStartDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      this.getTimezoneOffset({
                        date: validityStartDate,
                      })
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              validityEndDate: {
                type: "String",
                value: validityEndDate
                  ? this.$options.filters.getTimeDate(
                      validityEndDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      {
                        days: 1,
                        ...this.getTimezoneOffset({
                          date: validityEndDate,
                          minusSeconds: 1,
                        }),
                      }
                    ).utc
                  : "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        if (hasRulesAndExclusionsChanges) {
          collectionQueries.push({
            updateExclusionAndRuleItem: {
              exclusions: {
                type: "VbsConversionRuleExclusionUpdateInput!",
                value: paramExclusions,
              },
              ruleItems: {
                type: "[VbsConversionRuleItemMappingUpdateInput!]!",
                value: paramRuleItems,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }

        const params = {
          variables: {
            id: {
              type: "UUID!",
              value: this.conversionRule.id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries,
        };

        const result = await actions.updateVBSConversionRule(params);
        let success = false;
        let errors = [];
        
        Object.keys(result).forEach(key => {
          const item = result[key];
          if (item && typeof item === "object") {
            success = item.success;
            errors = item.errors ? [...errors, ...item.errors] : errors;
          }
        });

        this.isSaving = false;
        if (success) {
          this.showNotifyMessage({
            message: "Conversion Rule changes were saved",
            type: "success",
          });
          this.setSectionEditMode(false);
          this.getConversionRuleByID();
        } else {
          const errorMesssage = errors.length > 0
              ? errors[0]
              : "Problem has occurred while saving data.";
          this.showNotifyMessage({
            message: errorMesssage,
            type: "danger",
          });
        }
      } catch (e) {
        this.isSaving = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    getCurrentTab(title) {
      if (title === "Payor") {
        const paramNotIncludedSubGroup = this.tempSelectedSubGroup
          .map((item) => {
            return item.value;
          })
          .join(",");

        this.$eventBus.$emit(
          "insuranceCompaniesSetAdditionalFilter",
          paramNotIncludedSubGroup
            ? {
                enum_notInPayorSubGroup: `[${paramNotIncludedSubGroup}]`,
                logicalOperator: "AND",
              }
            : null
        );
      }
    },

    handleScroll(event) {
      const scrollableHeight =
        event.target.scrollingElement.offsetHeight - window.innerHeight;
      const footerOffset = 60;
      if (
        window.scrollY >= scrollableHeight - footerOffset &&
        !this.isAtPageBottom
      ) {
        this.isAtPageBottom = true;
      }
      if (
        window.scrollY < scrollableHeight - footerOffset &&
        this.isAtPageBottom
      ) {
        this.isAtPageBottom = false;
      }
    },

    renameProperty(obj, newKey, oldKey) {
      Object.defineProperty(
        obj,
        newKey,
        Object.getOwnPropertyDescriptor(obj, oldKey)
      );
      delete obj[oldKey];
    },

    renameRuleItem(ruleItem) {
      if (ruleItem.modifier) {
        this.renameProperty(ruleItem.modifier, "label", "modifierCode");
        this.renameProperty(ruleItem.modifier, "value", "id");
      }
      if (ruleItem.cptCode) {
        this.renameProperty(ruleItem.cptCode, "label", "code");
        this.renameProperty(ruleItem.cptCode, "value", "id");
      }
      if (ruleItem.test) {
        this.renameProperty(ruleItem.test, "value", "id");
      }
    },

    async getConversionRuleByID() {
      try {
        let result = await actions.getVbsConversionRule({
          variables: {
            property: {
              id: {
                type: "UUID!",
                value: this.$route.params.id,
              },
            },
          },
          limitData: [
            "name",
            "internalId",
            "description",
            "description",
            "validityStartDate",
            "validityEndDate",
            "exclusionPayorSubGroups",
            this.buildSubQuery("exclusionClients,", [
              "payorCode",
              "payorName",
              "archived",
            ]),
            this.buildSubQuery("exclusionPayors,", [
              "name",
              "insuranceCompanyId",
              "payorSubGroup",
              "inactive",
            ]),
            this.buildSubQuery("ruleItems", [
              this.buildSubQuery("inbound", [
                "id",
                this.buildSubQuery("test", ["label"]),
                this.buildSubQuery("cptCode", ["code"]),
                this.buildSubQuery("modifier", ["modifierCode"]),
                "unit",
              ]),

              this.buildSubQuery("convertAs", [
                "id",
                this.buildSubQuery("test", ["label"]),
                this.buildSubQuery("cptCode", ["code"]),
                this.buildSubQuery("modifier", ["modifierCode"]),
                "unit",
              ]),

              "created",
              "lastModified",
            ]),
            "created",
          ],
        });

        const {
          exclusionPayorSubGroups,
          exclusionClients,
          exclusionPayors,
          ruleItems,
        } = result;

        ruleItems.forEach((ruleItem) => {
          const { inbound, convertAs } = ruleItem;
          this.renameRuleItem(inbound);
          convertAs.forEach((convertAs) => {
            this.renameRuleItem(convertAs);
          });
        });

        this.conversionRule = JSON.parse(
          JSON.stringify({
            id: result.id,
            ruleID: result.internalId,
            ruleName: result.name,
            description: result.description,
            creationDate: this.$options.filters.getTimeDate(
              result.created,
              "MM/DD/YYYY"
            ).zone,
            validityStartDate: this.$options.filters.getTimeDate(
              result.validityStartDate,
              "MM/DD/YYYY"
            ).zone,
            validityEndDate: result.validityEndDate
              ? this.$options.filters.getTimeDate(
                  result.validityEndDate,
                  "MM/DD/YYYY"
                ).zone
              : "",
            ruleItems: result.ruleItems.map((ruleItem) => ({
              ...ruleItem,
              inbound: ruleItem.inbound,
              outbound: ruleItem.convertAs,
              created: ruleItem.created
                ? this.$options.filters.getTimeDate(
                    ruleItem.created,
                    "MM/DD/YYYY"
                  ).zone
                : "",
              lastModified: ruleItem.lastModified
                ? this.$options.filters.getTimeDate(
                    ruleItem.lastModified,
                    "MM/DD/YYYY"
                  ).zone
                : "",
            })),
          })
        );

        this.selectedSubGroup = [...exclusionPayorSubGroups].map((item) => {
          return {
            label: this.formatTitle(item),
            value: item,
          };
        });

        this.tempSelectedSubGroup = [...this.selectedSubGroup];
        this.selectedPayorClients.payor = [...exclusionPayors].map((payor) => ({
          ...payor,
          payorSubGroupEnum: payor.payorSubGroup,
          status: !payor.inactive ? "Active" : "Deactivated",
        }));

        this.selectedPayorClients.client = [...exclusionClients].map(
          (client) => ({
            ...client,
            status: !client.archived ? "Active" : "Deactivated",
          })
        );
        this.setCopyValues();
      } catch (e) {
        this.showNotifyMessage({
          message: "Unable to fetch data.",
          type: "danger",
        });
      }
    },
    setCopyValues() {
      this.copy.conversionRule = JSON.parse(
        JSON.stringify(this.conversionRule)
      );
      this.copy.selectedSubGroup = [...this.selectedSubGroup];
      this.copy.selectedPayorClients = JSON.parse(
        JSON.stringify(this.selectedPayorClients)
      );
    },
  },
  async created() {
    this.type = this.$route.name === "add-rules" ? TYPE_ADD : TYPE_EDIT;
    this.isLoading = true;
    if (this.type === TYPE_EDIT) {
      this.canEditExclusion = false;
      await this.getConversionRuleByID();
    } else {
      this.canEditExclusion = true;
    }
    await this.getAllPayorSubGroup();
    this.isLoading = false;

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
